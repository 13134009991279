html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Proxima Nova', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 375px;
  display: flex;
  flex-direction: column;
}
