.selectedLink {
  border-bottom: 4px solid #0f4b8f;
}

a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none !important;
}
a:active {
  text-decoration: none;
}

.tooltip-inner {
  background-color: #0f4b8f !important;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1300px !important;
  }
}
